// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React, { useEffect, useRef, useState } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

export default function PrivateRoute(props){

    const [isAuthenticated,setIsAuthenticated] = useState(null); // determine if authorized, from context or however you're doing it\
    const [refresh,setRefresh] = useState(0); // determine if authorized, from context or however you're doing it
    const authCallback = (childAuthenticated) => {
        setIsAuthenticated(childAuthenticated)
    }
    const tryingReauth = useRef(false);
    const finishedReauth = useRef(false);

    const GetIsAuthenticated = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem("jwt_token") }
        };

        const response = fetch('/api/user/name', requestOptions)
            .then(function(response){
                if (response.status != 200){
                    setIsAuthenticated(false);
                    throw new Error('NO DATA')
                } else {
                    setIsAuthenticated(true);
                    console.log("AUTHENTICATED");
                }
            })
            .catch(function(error){
                setIsAuthenticated(false);
                console.log("NOT AUTHENTICATED");
            });
    }

    useEffect(() => {
        GetIsAuthenticated();
    },[])

    useEffect(() => {
        setRefresh((prev) => {
            return prev+1;
        })
        console.log("refresh");
    },[isAuthenticated])

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page

    function GivePage({authenticated}){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem("jwt_token_refresh") }
        };
        
        if (authenticated !== null){
            if (!authenticated && !tryingReauth.current){
                setIsAuthenticated(null);
                tryingReauth.current = true;
                fetch("/api/security/refreshtoken",requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.token === undefined){
                        console.log("no auth");
                        setIsAuthenticated(false);
                    } else {
                        localStorage.setItem("jwt_token",data.token);
                        localStorage.setItem("jwt_token_refresh",data.token_refresh);
                        setIsAuthenticated(true);
                    }
                    finishedReauth.current = true;
                })
                .catch(function(error){
                    setIsAuthenticated(false);
                    console.log("ERROR IN REFRESH "+error)
                    finishedReauth.current = true;
                });
            }
            console.log("SPLIT 1")
            if (tryingReauth.current && !finishedReauth.current){
                return <div/>
            }
            tryingReauth.current = false;
            finishedReauth.current = false;
            console.log("SPLIT 2")
            return ( authenticated ? <Outlet context={[authCallback]} /> : <Navigate to="/"/>)
        } else {
            return <div/>
        }
    }

    return (
        <>
        <GivePage authenticated={isAuthenticated}/>
        </>
    )
}