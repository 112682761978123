import React, { Children, Component, useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { useConfig } from "../../config";

export default function TwitchAuthentication(props) {
    const config = useConfig();

    //HOOKS
    const navigate = useNavigate();
    const [token,setToken] = useState(""); //Used purely to force an update lmao
    let [isAuthenticated,setIsAuthenticated] = useState(""); //Used purely to force an update lmao
    const location = useLocation();

    //GETTING URL CODE IF PRESENT
    const client_id = "taihesckfrt72czetzczjfuspv8hx6";
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const error = searchParams.get("error");

    try{
        console.log(props.landingUri);
    } catch (error){
        console.log("nouri")
    }
    
    //Check if the user is already authenticated
    useEffect(() => {
        if (isAuthenticated == ""){
            setIsAuthenticated("false");
        }
    },[]);
    
    //Moving on to customize page if authenticated or got token. Checked after every render. Go to error page if unauthorized by twitch to get token
    useEffect(() => {
        if (error != null){
            navigate("/aw"); 
        }
        if (token != "" || isAuthenticated == "true"){
            console.log("REDIRECT");
            navigate("/customize"); 
        } else {
            console.log("REDIRECT REJECTED");
        }
        
    });
    
    //If we checked authentication and if we have a code, acquire a token. This is checked once but that's okay, because once we get redirected from twitch this whole page state refreshes and this will automatically check with a code. it just won't run BEFORE the redirect
    useEffect(() => {
        if (isAuthenticated == "false" && code != null){
            if (code != null && token == ""){
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ authorizationCode: code })
                };
                
                console.log("GET THE TOKEN WITH "+code);

                const response = fetch( '/api/security/twitchauthcode', requestOptions)
                    .then(function(response){
                        if (response.status != 200) throw("NO TOKEN RECIEVED");
                        return response.json();
                    })
                    .then(function(parsedData){
                        localStorage.setItem("jwt_token",parsedData.token);
                        localStorage.setItem("jwt_token_refresh",parsedData.token_refresh);
                        setToken(parsedData.token);
                    })
                    .catch(function(error){
                        console.log(error)
                    });
                console.log("GETTING TOKEN");
            }
        }
    }, [isAuthenticated]);
    
    //Either wait for authentication check, or exit because we're authenticated. otherwise move on
    if (isAuthenticated != "false" || config == null) {return null;}

    if (code==null){
        console.log("twitch redir to "+config.frontend_hostname);
        window.location.href = 'https://id.twitch.tv/oauth2/authorize?response_type=code&client_id='+client_id+'&redirect_uri='+config.frontend_hostname;
    }
    
    return null;
}
