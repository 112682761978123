import React, { useEffect, useRef, useState } from 'react'
import fetchWrapper from './fetchWrapper';
import "./JigsawSwapBoard.css"

import JigsawTile from './JigsawTile';
import { useOutletContext } from 'react-router-dom';
import { doshekno, tails, opentest, pizzatower} from '../images/jigsaw';
import * as pizzatower_tiles from '../images/jigsaw/pizzatower';

export default function JigsawSwapBoard({fetchAuth, game, index, updateCallback, canUpdate, updatePinkCallback}) {
    
    const [activePieces,setActivePieces] = useState([]);
    const [newPieces,setNewPieces] = useState([]);
    const [dupePieces,setDupePieces] = useState([]);
    const [gotPuzzle,setGotPuzzle] = useState(false);
    const [updatePink,setUpdatePink] = useState(0);
    const [updateState, setUpdateState] = useState(0); //0 means waiting to update new pieces. 1 means updating. 2 means updated
    const [overlayFinished, setOverlayFinished] = useState(false);
    const consumedNewData = useRef(false);

    const [jigsawData, setJigsawData] = useState(undefined);
    /*if (puzzleMap.has(game)){
      const gameData = puzzleMap.get(game);
      image_width = gameData[0];
      image_height = gameData[1];
      background = gameData[2];
      pieces = gameData[3];
    }*/
    
    const gap = 3;
    const [piece_columns,setPiece_Columns] = useState(-1);
    const [piece_rows,setPiece_Rows] = useState(-1);
    //const piece_width = Math.floor(image_width / piece_columns) - 1; //minus 1 becaues it over extends just a tad
    //const piece_height = Math.floor(image_height / piece_rows) - 1;  //minus 1 becaues it over extends just a tad
    
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem("jwt_token") }
    };

    //Get board data and images
    useEffect(() => {
      fetchAuth("/api/jigsaw/data/"+game,requestOptions)
          .then((parsedData) => {
            let data = new Map();
            if (parsedData !== null){
                let metadata = parsedData.metadata;
                if (metadata != null){
                  let split = metadata.split;
                  if (split != null){
                    setPiece_Rows(split[0]);
                    setPiece_Columns(split[1]);
                  }

                  let dimensions = metadata.dimensions;
                  if (dimensions != null){
                    data.set("image_width", dimensions[0]);
                    data.set("image_height", dimensions[1]);
                    data.set("piece_width", dimensions[2]);
                    data.set("piece_height", dimensions[3]);
                  }

                  data.set("has_cosmetic", metadata.has_cosmetic);
                }

                let image = parsedData.image;
                if (image != null){
                  if (!Array.isArray(image)){
                    /*const byteCharacters = atob(result);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);

                    let image = new Blob([byteArray], { type: 'image/jpeg' });*/
                    data.set("fullImage", `data:image/png;base64,${image}`);
                  } else {
                    //data.set("pieceImages", `data:image/png;base64,${image}`);
                    data.set("pieceImages", image);
                  }
                }
            }
            setJigsawData(data);
            console.log(parsedData);
       });
    },[activePieces])
    
    /*useEffect(() => {
      fetchAuth("/api/jigsaw/data/"+game,requestOptions)
          .then((parsedData) => {
            if (parsedData !== null){
                setPiece_Columns(parsedData.board_columns);
                setRows_Columns(parsedData.board_rows);
            }
       });
    },[]) */

    useEffect(() => {
      fetchAuth("/api/user/jigsaw/getpuzzle/"+game,requestOptions)
          .then((parsedData) => {
            if (parsedData != null){
              setActivePieces(parsedData.owned_pieces);
              setNewPieces(parsedData.new_pieces);
              setDupePieces(parsedData.new_pieces_pink);

              let _hasNew = false;
              if (parsedData.new_pieces != undefined && parsedData.new_pieces.length > 0){
                _hasNew = true;
              }
              if (parsedData.new_pieces_pink != undefined && parsedData.new_pieces_pink.length > 0){
                _hasNew = true;
              }
              updateCallback(index, _hasNew);
            }
      });
    },[])

    useEffect(() => {
      updatePinkCallback((prev) => {return prev+1})
      fetchAuth("/api/user/jigsaw/getpuzzle/"+game,requestOptions)
          .then((parsedData) => {
            if (parsedData != null){
              setActivePieces(parsedData.owned_pieces);
              setNewPieces(parsedData.new_pieces);
              setDupePieces(parsedData.new_pieces_pink);
            }
      });
    },[updatePink])

    const delay = ms => new Promise(res => setTimeout(res, ms));
    
    useEffect(() => {
      if (canUpdate){
        //console.log("YES UPDATE");
        setUpdateState(1);
      }
    },[canUpdate])

    useEffect(() => {
      console.log(piece_columns+" "+piece_rows+" "+activePieces+" ");
      if (piece_columns != -1 && piece_rows != -1 && activePieces.length === piece_columns*piece_rows){
        setOverlayFinished(true);
      }
    },[activePieces,piece_columns,piece_rows])

    if (jigsawData == undefined){
      return null;
    }

    function DrawTiles({active, newpiece, duplicates, pieces}){
        if (pieces == undefined){
          return <div/>
        }
        const tileRender = (<div>
            {
              [...Array(piece_columns * piece_rows)].map((e, i) => {
                let isActive = active.includes(i);
                let isNew = newpiece.includes(i);
                let isDupe = duplicates.includes(i);
                
                let image = undefined;
                console.log("PIECES STUFF "+pieces)
                if (Array.isArray(pieces)){
                  for(let j = 0; j < pieces.length; j++){
                    if (pieces[j].piece == i){
                      image = `data:image/png;base64,${pieces[j].image}`;
                    }
                  }
                }

                return (
                  <JigsawTile
                    gap={gap}
                    piece_columns={piece_columns}
                    index={i}
                    key={i}
                    active={isActive}
                    isnew={isNew}
                    isduplicate={isDupe}
                    background={image}
                    fetchAuth={fetchAuth}
                    canUpdate={canUpdate}
                    piece_width={jigsawData.get("piece_width")}
                    piece_height={jigsawData.get("piece_height")}
                    usedPinkCallback={setUpdatePink}
                    game={game}/>
                )
              })
            }
          </div>)
        return tileRender;
    }

    const boardStyle = {
      width: jigsawData.get("image_width")+gap*2,
      height: jigsawData.get("image_height")+gap*2
    }

    const overlayStyle = {
      width: jigsawData.get("image_width"),
      height: jigsawData.get("image_height"),
      backgroundImage: 'url("'+jigsawData.get("fullImage")+'")'
    }

    var overlayStyleGlow = {
      width: jigsawData.get("image_width"),
      height: jigsawData.get("image_height"),
      backgroundColor: "#fffefa",
      color: "#fff4d4",
      opacity: 1
    }

    var frameStyle = {
      borderColor: (jigsawData.get("has_cosmetic") == true ? "rgb(249, 255, 131)" : "rgb(172, 72, 174)")
    }

    console.log("overlay "+overlayFinished);
    return (
      <>
        <div className="frame" style={frameStyle}>
        { !overlayFinished ? (
        <div className="board" style={boardStyle}>
          <DrawTiles active={activePieces} newpiece={newPieces} duplicates={dupePieces} pieces={jigsawData.get("pieceImages")}/>
        </div>) : (
        <>
        <div className="overlayFinished" style={overlayStyle}>
          <div className="overlayFinishedGlow" style={overlayStyleGlow}/>
        </div>
        </>
        )
        }
        </div>
        <div className="jigsawNameText" style={{padding:".5em", textShadow:"0px 0px 0px 1em rgb(0, 0, 0);"}}>
          {game}
        </div>
      </>
  )
}
