import tile000 from './tile000.png'
import tile001 from './tile001.png'
import tile002 from './tile002.png'
import tile003 from './tile003.png'
import tile004 from './tile004.png'
import tile005 from './tile005.png'
import tile006 from './tile006.png'
import tile007 from './tile007.png'
import tile008 from './tile008.png'

export default {
    tile000,
    tile001,
    tile002,
    tile003,
    tile004,
    tile005,
    tile006,
    tile007,
    tile008
}