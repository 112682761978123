import React from 'react'

export default function fetchWrapper (uri, options, authSet){
    const handleResponse = (response) => {
        if (response.status !== 200){
            return null;
        }

        return response.text().then(text => {
           // console.log(text);
            
            if (!response.ok) {
                if ([401, 403].includes(response.status)) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    authSet(false);
                }
            }
            
            try {
                const data = text && JSON.parse(text);
                return data;
            } catch(error){
                const data = text;
                return data;
            }
        })
    }

    return fetch(uri, options).then(handleResponse);
}
