import { useEffect, useState } from "react";

let config = null;

export const useConfig = () => {
  const [localConfig, setLocalConfig] = useState(config);

  useEffect(() => {
    if (config) {
      setLocalConfig(config);
      return;
    }

    const fetchConfig = async () => {
      const response = await fetch("/config.json");
      if (!response.ok) {
        console.error("Failed to load configuration file.");
        return;
      }
      const json = await response.json();
      config = json;
      setLocalConfig(json);
    };

    fetchConfig();
  }, []);

  return localConfig;
};

export const getConfig = () => config;