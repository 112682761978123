import React, {useEffect, useState} from 'react'
import './CosmeticSelector.css'
import {FaceAnalog, FaceClownNose, FaceNothing, FaceScar,FaceSleepyEvil, FaceXEyes, FaceNothink, FaceSunglasses} from '../images/bunnycustomization/Face'
import {HatAstronaut, HatBandage,HatBeanie,HatBump,HatCowboy,HatLuna,HatBow, HatChef} from '../images/bunnycustomization/Hat'
import {ShirtApron, ShirtBib, ShirtButt, ShirtGlizzy, ShirtHandcuffs,ShirtHole,ShirtJacket, ShirtPoncho, ShirtTie} from '../images/bunnycustomization/Shirt'
import {ShoesBoots,ShoesClown,ShoesPlatform,ShoesSocks} from '../images/bunnycustomization/Shoes'
import {css} from '@emotion/react'

function getCosmeticMap(){
    let cosmeticMap = new Map();
    cosmeticMap.set('',['', [263, 430]]);
    cosmeticMap.set('FaceScar',[FaceScar, [263, 430]]);
    cosmeticMap.set('FaceSleepyEvil',[FaceSleepyEvil, [266, 470]]);
    cosmeticMap.set('FaceClownNose',[FaceClownNose, [266, 470]]);
    cosmeticMap.set('FaceAnalog',[FaceAnalog, [266, 470]]);
    cosmeticMap.set('FaceXEyes',[FaceXEyes, [266, 470]]);
    cosmeticMap.set('FaceNothing',[FaceNothing, [266, 470]]);
    cosmeticMap.set('FaceNothink',[FaceNothink, [266, 470]]);
    cosmeticMap.set('FaceSunglasses',[FaceSunglasses, [266, 470]]);
    cosmeticMap.set('HatBandage',[HatBandage, [164, 238]]);
    cosmeticMap.set('HatLuna',[HatLuna, [164, 238]]);
    cosmeticMap.set('HatAstronaut',[HatAstronaut, [164, 238]]);
    cosmeticMap.set('HatBump',[HatBump, [164, 238]]);
    cosmeticMap.set('HatCowboy',[HatCowboy, [164, 238]]);
    cosmeticMap.set('HatBeanie',[HatBeanie, [164, 238]]);
    cosmeticMap.set('HatBow',[HatBow, [164, 238]]);
    cosmeticMap.set('HatChef',[HatChef, [164, 238]]);
    cosmeticMap.set('ShirtHandcuffs',[ShirtHandcuffs, [269, 596]]);
    cosmeticMap.set('ShirtJacket',[ShirtJacket, [266, 595]]);
    cosmeticMap.set('ShirtHole',[ShirtHole, [266, 595]]);
    cosmeticMap.set('ShirtBib',[ShirtBib, [266, 595]]);
    cosmeticMap.set('ShirtButt',[ShirtButt, [266, 595]]);
    cosmeticMap.set('ShirtTie',[ShirtTie, [266, 595]]);
    cosmeticMap.set('ShirtPoncho',[ShirtPoncho, [266, 595]]);
    cosmeticMap.set('ShirtGlizzy',[ShirtGlizzy, [266, 595]]);
    cosmeticMap.set('ShirtApron',[ShirtApron, [266, 595]]);
    cosmeticMap.set('ShoesBoots',[ShoesBoots, [268, 779]]);
    cosmeticMap.set('ShoesSocks',[ShoesSocks, [271, 787]]);
    cosmeticMap.set('ShoesClown',[ShoesClown, [271, 787]]);
    cosmeticMap.set('ShoesPlatform',[ShoesPlatform, [271, 787]]);
    return cosmeticMap;
}

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function fillCosmeticMap(infoMap, setter){
    let returnMap = getCosmeticMap();
    infoMap.map((value, index) => {
        let key = value[0];
        let mapCurrentContents = returnMap.get(key);
        returnMap.set(key, [...mapCurrentContents,...value[1]])
        //console.log("scramaDOO: "+key+" "+value[1]);
    })
    setter(returnMap);
    return returnMap;
}

function CosmeticSelector(props) {
    const leftArrow = '<'
    const rightArrow = '>'
    const [clickCount, setClickCount] = useState(props.cosmetics.indexOf(props.wearingItem));
    const [parsedMapProp, setParsedMapProp] = useState(false);
    const [cosmeticMap,setCosmeticMap] = useState(getCosmeticMap());
    const baseWidth = 552; //Used for calculating overall image scale
    
    
    //Map preperation
    useEffect(() => {
        setParsedMapProp(true);
        fillCosmeticMap(props.cosmetic_info_map, setCosmeticMap);
    },[]);

    //Telling father what we're wearing
    useEffect(() => {
        props.wearingCallBack(props.cosmetics[clickCount]);
    });

    //Navigating items
    const NextItem = () =>{
        setClickCount((clickPrev) =>{
            return Math.min(clickPrev+1, props.cosmetics.length-1)
        });
    }
    const PrevItem = () =>{
        setClickCount((clickPrev) =>{
            return Math.max(clickPrev-1, 0)
         });
    }

    //refetching
    useEffect(()=>{
        fillCosmeticMap(props.cosmetic_info_map, setCosmeticMap);
    }, [props.cosmetic_info_map])

    //cosmetic image constants
    const imageScaling = (baseWidth - props.image_width)/baseWidth;
    const spacing = props.image_width*.6;
    const unselected_padding = props.image_width * 1.3;
    const circle_radius = 150;
    const notUnlockedOpacity = .6;
    const unlockedOpacityCircle = .2;

    //COSMETIC IMAGE LOOP
    const cosmeticImageLoop = props.cosmetics.map((image, index) => {
        if (image == "") return <div></div>

        let x = spacing*(index-clickCount);
        if (index < clickCount) x -= unselected_padding;
        if (index > clickCount) x += unselected_padding;
        
        let scale = .4;
        if (index == clickCount) scale = 1;

        let yoffset = 0;
        if (index != clickCount) yoffset = props.unselected_yoffset;

        let cosmetic_info = cosmeticMap.get(image);
        let cosmetic_coords = cosmetic_info[1];
        let cosmetic_y = cosmetic_coords[1] * imageScaling * (scale == 1 ? 0 : scale);
        let cosmetic_x = cosmetic_coords[0] * imageScaling * (scale == 1 ? 0 : scale);

        //Unlocked?
        let cosmetic_opac = cosmetic_info[3];
        if (scale == 1) {cosmetic_opac = true;}

        let circleColor = rgbToHex(58, 58, 58);
        
        let imageStyle = {
            top:props.image_y+yoffset, //props.image_y+props.yoffset
            left:x,
            transform: 'scale('+scale+')',
            transition: 'all 0.3s ease',
            opacity: (cosmetic_opac == true ? 1 : notUnlockedOpacity)
        }

        let circle_y = cosmetic_coords[1] * imageScaling * scale;
        let circle_x = (cosmetic_coords[0] - baseWidth) * imageScaling * (scale == 1 ? 0 : scale);

        let circleStyle = {
            top:yoffset+props.circle_yoffset + circle_y,
            transition: 'all 0.3s ease',
            width: circle_radius * (scale == 1 ? 0 : 1),
            height: circle_radius * (scale == 1 ? 0 : 1),
            pointerEvents : 'none',
            color: circleColor,
            opacity: (cosmetic_opac == true ? unlockedOpacityCircle : 1)
        }
        //const css = 
        //css={css` transform-origin: 100%, ${props.transform_y_origin}%; } `}
        return (
        <>
            <div className="centerimage" style={imageStyle}>
                <div className="circley centerimage" style={circleStyle} />
                <img className="centerimage" src={cosmetic_info[0]} alt="" width={props.image_width}/>
            </div>
        </>
        )
    })

    return (
        <div>
            {cosmeticImageLoop}
            <div className = "buttonposition big-white-text" style={{top: props.button_y}}>
                <div className = "css-button-3d--green" onClick={PrevItem}>
                    {leftArrow}
                </div><div className="css-button-3d--green" onClick={NextItem}>
                    {rightArrow}
                </div>
            </div>
        </div>
    )
}

export default CosmeticSelector;