import React from 'react'
import './AuthenticationFailed.css'

export default function CantFindPage() {
  return (
    <>
    <h1 className='wavy'>{":o huh? i cant find this page ..."}</h1>
    <div>{"sorry i know it's not very intuitive yet"}</div>
    </>
  )
}
