import React, {useEffect, useState}  from 'react'
import {Navigate, useNavigate, useOutletContext} from 'react-router-dom'
import CosmeticSelector from "../components/CosmeticSelector";
import {BaseBunny, BunnyCoin} from '../images/bunnycustomization'
import './PageCustomize.css'
import '../components/CosmeticSelector.css'
import useSound from 'use-sound'
import LoadingSpinner from '../images/LoadingSpinner'
import fetchWrapper from '../components/fetchWrapper';

export default function PageCustomize() {
    //AUTHENTICATION REQUIREMENTS
    const [setIsAuthenticated] = useOutletContext();
    const navigator = useNavigate();

    const fetchAuth = (uri, request) =>{
        return fetchWrapper(uri,request, setIsAuthenticated);
    }

    //bunny image constants
    const image_y = 40;
    const image_width = 300;
    const buttonBase_y = 100;

    //FETCH DATA set cosmetic information
    let expectedDataResponseCount = 11;
    const [gotData, setGotData] = useState(0);
    const addGotData = () => { setGotData((prevGotData) => {return prevGotData+1}); }
    const [reFetch, setReFetch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [user, setUsername] = useState("UNKNOWN");
    const [bunnycoin, setBunnycoin] = useState(0);
    const [wearingHat, setWearingHat] = useState("");
    const [wearingFace, setWearingFace] = useState("");
    const [wearingShirt, setWearingShirt] = useState("");
    const [wearingShoes, setWearingShoes] = useState("");
    const [cosmeticsHatAll, setCosmeticsHatAll] = useState([""]);
    const [cosmeticsFaceAll, setCosmeticsFaceAll] = useState([""]);
    const [cosmeticsShirtAll, setCosmeticsShirtAll] = useState([""]);
    const [cosmeticsShoesAll, setCosmeticsShoesAll] = useState([""]);
    const [cosmeticsHatAllMap, setCosmeticsHatAllMap] = useState(new Map());
    const [cosmeticsFaceAllMap, setCosmeticsFaceAllMap] = useState(new Map());
    const [cosmeticsShirtAllMap, setCosmeticsShirtAllMap] = useState(new Map());
    const [cosmeticsShoesAllMap, setCosmeticsShoesAllMap] = useState(new Map());
    let cosmeticMapHat = new Map();
    let cosmeticMapFace = new Map();
    let cosmeticMapShirt = new Map();
    let cosmeticMapShoes = new Map();
    const getMapInitialArray = () => {return [0,false]};

    //functions for managing cosmetic data
    const setMapKeyCost = (map, key, cost) => {
        if (!map.has(key)){ map.set(key, getMapInitialArray()); }

        let arr = map.get(key);
        arr[0]=cost;
    }
    const setMapKeyOwned = (map, key, owned) => {
        if (!map.has(key)){ map.set(key, getMapInitialArray()); }

        let arr = map.get(key);
        arr[1]=owned;
    }
    const handleCosmeticAllData = (parsedData, map, setter) => {
        let cosmeticArray = [""];
        parsedData.map((data, index) => {
            cosmeticArray = [...cosmeticArray, data.name];
            setMapKeyCost(map, data.name, data.cost);
        })
        setter(() => {return cosmeticArray});
        addGotData();
    }
    const handleCosmeticOwnedData = (parsedData, map) => {
        parsedData.cosmetics.split(" ").map((data, index) => {
            setMapKeyOwned(map, data, true);
        })
        setMapKeyOwned(map, "", true); //default empty to owned
        addGotData();
    }
    const wearingIsOwned = (wearing, map) => {
        if (wearing == "") return true;
        let arr = map.get(wearing);
        return arr[1];
    }
    const wearingGetCost = (wearing, map) => {
        if (wearing == "") return 0;
        if (wearingIsOwned(wearing, map)) return 0;
        let arr = map.get(wearing);
        console.log("huh "+arr);
        return arr[0];
    }

    //Redirection hook
    const navigate = useNavigate();
    const TriggerRefetch = () => {
        setReFetch((prevValue) => {return !prevValue})
    }
    
    let wearRequest = "";
    const [woreItems,setWoreItems] = useState(0);

    const wearItemsHandler = () =>{
        wearRequest = "";
        wearRequest += wearingHat; wearRequest += (wearingHat == "" ? "": " ");
        wearRequest += wearingFace; wearRequest += (wearingFace == "" ? "": " ");
        wearRequest += wearingShirt; wearRequest += (wearingShirt == "" ? "": " ");
        wearRequest += wearingShoes; wearRequest += (wearingShoes == "" ? "": " ");

        const requestOptionsWear = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem("jwt_token") },
            body: JSON.stringify({ wearing: wearRequest })
        };

        console.log("start loading WEAR "+wearRequest)
        setIsLoading(true);
        const responseWear = fetchAuth('/api/user/wearing', requestOptionsWear)
            .then(function(parsedData){
                console.log("stop loading WEAR")
                setIsLoading(false);
                setWoreItems(prevItem => {return prevItem+1});
            });
    }

    const buyItemsHandler = () =>{
        wearRequest = "";
        wearRequest += wearingHat; wearRequest += (wearingHat == "" ? "": " ");
        wearRequest += wearingFace; wearRequest += (wearingFace == "" ? "": " ");
        wearRequest += wearingShirt; wearRequest += (wearingShirt == "" ? "": " ");
        wearRequest += wearingShoes; wearRequest += (wearingShoes == "" ? "": " ");

        const requestOptionsWear = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem("jwt_token") },
            body: JSON.stringify({ owned_cosmetics: wearRequest })
        };

        console.log("start loading BUY")
        setIsLoading(true);
        const responseBuy = fetchAuth('/api/user/purchase', requestOptionsWear)
            .then(function(parsedData){
                TriggerRefetch();
                console.log("stop loading BUY")
                setIsLoading(false);
            });
    }

    //Prepare for base data requests
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem("jwt_token") }
    };
    
    //Refetch children if any child data changes
    //useEffect(() => {
    //    TriggerRefetchChild();
    //},[cosmeticsHatAllMap, cosmeticsFaceAllMap, cosmeticsShirtAllMap, cosmeticsShoesAllMap])

    //Check if authenticated before loading any data
    

    //IF AUTHENTICATED, load all necessary page/app data
    useEffect(() => {

            // USERNAME
            const responseUsername = fetchAuth('/api/user/name', requestOptions)
                .then(function(parsedData){
                    setUsername(parsedData.username);
                    addGotData();
                });
            const responseBunnycoin = fetchAuth('/api/user/bunnycoin', requestOptions)
                .then(function(parsedData){
                    setBunnycoin(parsedData.bunnycoin);
                    addGotData();
                });
            
            // GET AVAILABLE COSMETICS and their costs
            const responseCosmeticsHatAll = fetchAuth('/api/cosmetics/hat', requestOptions)
                .then((parsedData) => {
                    handleCosmeticAllData(parsedData, cosmeticMapHat, setCosmeticsHatAll)
                    setCosmeticsHatAllMap(cosmeticMapHat);
                });
            
            const responseCosmeticsFaceAll = fetchAuth('/api/cosmetics/face', requestOptions)
                .then((parsedData) => {
                    handleCosmeticAllData(parsedData, cosmeticMapFace, setCosmeticsFaceAll)
                    setCosmeticsFaceAllMap(cosmeticMapFace);
                });
            
            const responseCosmeticsShirtAll = fetchAuth('/api/cosmetics/shirt', requestOptions)
                .then((parsedData) => {
                    handleCosmeticAllData(parsedData, cosmeticMapShirt, setCosmeticsShirtAll)
                    setCosmeticsShirtAllMap(cosmeticMapShirt);
                });
            
            const responseCosmeticsShoesAll = fetchAuth('/api/cosmetics/shoes', requestOptions)
                .then((parsedData) => {
                    handleCosmeticAllData(parsedData, cosmeticMapShoes, setCosmeticsShoesAll)
                    setCosmeticsShoesAllMap(cosmeticMapShoes);
                });
            
            // OWNED COSMETICS
            const responseCosmeticsHat = fetchAuth('/api/user/cosmetics/hat', requestOptions)
                .then(function(parsedData){
                    handleCosmeticOwnedData(parsedData, cosmeticMapHat);
                    setCosmeticsHatAllMap(cosmeticMapHat);
                });
            
            const responseCosmeticsFace = fetchAuth('/api/user/cosmetics/face', requestOptions)
                .then(function(parsedData){
                    handleCosmeticOwnedData(parsedData, cosmeticMapFace);
                    setCosmeticsFaceAllMap(cosmeticMapFace);
                });
            
            const responseCosmeticsShirt = fetchAuth('/api/user/cosmetics/shirt', requestOptions)
                .then(function(parsedData){
                    handleCosmeticOwnedData(parsedData, cosmeticMapShirt);
                    setCosmeticsShirtAllMap(cosmeticMapShirt);
                });
            
            const responseCosmeticsShoes = fetchAuth('/api/user/cosmetics/shoes', requestOptions)
                .then(function(parsedData){
                    handleCosmeticOwnedData(parsedData, cosmeticMapShoes);
                    setCosmeticsShoesAllMap(cosmeticMapShoes);
                });

            //Currently wearing
            const responseWearing = fetchAuth('/api/user/wearing', requestOptions)
                .then(function(parsedData){
                    setWearingHat((prevarray) => {return (parsedData.hat == undefined ? "" : parsedData.hat)});
                    setWearingFace((prevarray) => {return (parsedData.face == undefined ? "" : parsedData.face)});
                    setWearingShirt((prevarray) => {return (parsedData.shirt == undefined ? "" : parsedData.shirt)});
                    setWearingShoes((prevarray) => {return (parsedData.shoes == undefined ? "" : parsedData.shoes)});

                    console.log("shirt wearing");
                    console.log(parsedData.shirt);
                    addGotData();
            });
    }, [reFetch])

    //what SHOULD HAPPEN is since all requests are in the use effect, it'll return null, do the effect, the effect will update states and keep reaching this point until this condition is met then it'll pass this point
    if (gotData < expectedDataResponseCount){
        console.log("recieved "+gotData+" responses expecting "+expectedDataResponseCount);
        return null;
    }

    const callBackHat = (childData) => {
        setWearingHat(childData);
    }
    const callBackFace = (childData) => {
        setWearingFace(childData);
    }
    const callBackShirt = (childData) => {
        setWearingShirt(childData);
    }
    const callBackShoes = (childData) => {
        setWearingShoes(childData);
    }

    let action_text = "WEAR";
    let action_color = "#80ed99";
    let action_shadowColor = "#57cc99";
    let buy_cost_display = (<></>)
    let action_handler = wearItemsHandler;
    if (!wearingIsOwned(wearingHat, cosmeticsHatAllMap) || !wearingIsOwned(wearingFace, cosmeticsFaceAllMap) || !wearingIsOwned(wearingShirt, cosmeticsShirtAllMap) || !wearingIsOwned(wearingShoes, cosmeticsShoesAllMap)){
        action_text = "BUY";
        action_color = "#dbe27a";
        action_shadowColor = "#cacc57";
        action_handler = buyItemsHandler;
        let totalCost = 0;
        totalCost += wearingGetCost(wearingHat, cosmeticsHatAllMap);
        totalCost += wearingGetCost(wearingFace, cosmeticsFaceAllMap);
        totalCost += wearingGetCost(wearingShirt, cosmeticsShirtAllMap);
        totalCost += wearingGetCost(wearingShoes, cosmeticsShoesAllMap);
        if (totalCost > bunnycoin){
            action_color = "#7c7c7c";
            action_shadowColor = "#6b6b6b";
            action_handler = () => {};
        }

        //buy_cost_display = <div className="luna_wawa_small BaseBunny" style={{top: bunnycoin_cost_y, left: bunnycoin_cost_x}}><img src={BunnyCoin} alt="BunnyCoin" className="BaseBunny" width={bunnycoin_cost_width}/>x{totalCost}</div>
        action_text += " -"+totalCost;
    }

    let flavor_text = "HI";
    if (user.toLowerCase() == "dekuloid"){
        flavor_text = "<3";
    }
    
    if (user.toLowerCase() == "miyaoowo"){
        flavor_text = "MYU";
    }

    if (user.toLowerCase() == "brujita0415"){
        flavor_text = "the great";
    }

    return (
        <div>
            <h2 className="luna_wawa">{flavor_text+" "+user}</h2>
            <div className="BaseBunny" style={{top: image_y}}><img src={BaseBunny} alt="BaseBunny" className="BaseBunny" width={image_width}/></div>
            <CosmeticSelector cosmeticStyle="SHOES" image_y={image_y} wearingCallBack = {callBackShoes} image_width={image_width} button_y={buttonBase_y+300}  cosmetics={cosmeticsShoesAll} wearingItem = {wearingShoes} unselected_yoffset={250} circle_yoffset={-40} cosmetic_info_map={[...cosmeticsShoesAllMap.entries()]}/>
            <CosmeticSelector cosmeticStyle="SHIRT" image_y={image_y} wearingCallBack = {callBackShirt} image_width={image_width} button_y={buttonBase_y+200} cosmetics={cosmeticsShirtAll} wearingItem = {wearingShirt} unselected_yoffset={180} circle_yoffset={-40} cosmetic_info_map={[...cosmeticsShirtAllMap.entries()]}/>
            <CosmeticSelector cosmeticStyle="FACE" image_y={image_y} wearingCallBack = {callBackFace} image_width={image_width} button_y={buttonBase_y+100} cosmetics={cosmeticsFaceAll} wearingItem = {wearingFace} unselected_yoffset={130} circle_yoffset={-50} cosmetic_info_map={[...cosmeticsFaceAllMap.entries()]}/>
            <CosmeticSelector cosmeticStyle="HAT" image_y={image_y} wearingCallBack = {callBackHat} image_width={image_width} button_y={buttonBase_y} cosmetics={cosmeticsHatAll} wearingItem = {wearingHat} unselected_yoffset={70} circle_yoffset={-50} cosmetic_info_map={[...cosmeticsHatAllMap.entries()]}/>
            
            <div className="buyRow">
                <div className="BunnyCoin">
                    <div className="luna_wawa_small">x{bunnycoin}</div>
                </div>
                <h1 onClick={action_handler} className = "big-white-text BuyButton css-button-3d--green" style={{background: action_color, boxShadow: '0 0px '+action_shadowColor}}> 
                    <div>{action_text}</div>
                    {isLoading ? <LoadingSpinner /> : <></>}
                </h1>
                <div className="jigsawButton_back" onClick={() => {navigator('/jigsaw-swap')}}>
                    <div className="jigsawButton_front"/>
                </div>
            </div>
        </div>
    )
}
//luna_wawa_small