import React from 'react'
import './AuthenticationFailed.css'

export default function AuthenticationFailed() {
    
  return (
    <>
    <h1 className='wavy'>{"Awww :[ we couldn't get permission from your twitch."}</h1>
    <div>{"This app only uses twitch's authentication servers to make sure you are who you say you are. It don't touch anything in your account! (pinky promise)"}</div>
    </>
  )
}
