import React, { useEffect, useRef, useState } from 'react'
import { Navigate, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import fetchWrapper from '../components/fetchWrapper';
import './JigsawSwap.css'
import JigsawSwapBoard from '../components/JigsawSwapBoard'

export default function JigsawSwap(props) {

    //AUTHENTICATION REQUIREMENTS
    const [setIsAuthenticated] = useOutletContext();
    const [pinkPieces,setPinkPieces] = useState(0);
    const [pinkShards,setPinkShards] = useState(0);
    const [gotPink, setGotPink] = useState(false);
    const [updatePink, setUpdatePink] = useState(0);
    const [boardUpdating, setBoardUpdating] = useState(-1);
    const [games, setGames] = useState(undefined)
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem("jwt_token") }
    };

    useEffect(() => {
      fetchAuth("/api/user/jigsaw/pink",requestOptions)
        .then((parsedData) => {
          if (parsedData != null){
            setPinkPieces(parsedData.pinkPieces);
            setPinkShards(parsedData.pinkShards);
            setGotPink(true);
          }
    })},[updatePink]);

    useEffect(() => {
      fetchAuth("/api/user/jigsaw/owned_puzzles",requestOptions)
        .then((parsedData) => {
          if (parsedData != null){
            setGames(parsedData);
          }
    })},[]);

    //const findingGame = useRef(false);
    //const [foundGame, setFoundGame] = useState(null);
    const navigator = useNavigate();

    const fetchAuth = (uri, request) =>{
        return fetchWrapper(uri,request, setIsAuthenticated);
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));
    let hasNew = []

    //Navigating items
    const updateCallback = (_index, _hasNew) => {
      const _length_prev = hasNew.length;
      hasNew[_index] = _hasNew;
      if (hasNew.length >= games.length && _length_prev < games.length){
        playBoardUpdates();
      }
    }

    const playBoardUpdates = async () => {
      console.log("PLAY BOARD UPDATES START - should happen ONCE");
      for (let i = 0; i < games.length; i++){
        if (hasNew[i]){
          //console.log("PLAYING "+i);
          window.location.href = "#jigsaw-"+(i+1);
          //console.log("SCROLL")
          await delay(500);
          console.log("UPDATE")
          setBoardUpdating(i);
          await delay(1500);
          console.log("DONT UPDATE")
          setBoardUpdating(-1);
          await delay(100);
        }
      }
      setBoardUpdating(-1);
    }

    /*const updateCallback = async (index) => {
      console.log("CALLBACK "+index);
      window.location.href = "#jigsaw-"+(index+1);
      console.log("SCROLL")
      await delay(500);
      console.log("UPDATE")
      setBoardUpdating(index);
      await delay(2000);
      console.log("NEXT")
    }*/

    if (!gotPink || games === undefined){
      return null;
    }

    const multiBoard = games.map((game, index) => {
      const id = "jigsaw-"+(index+1);
      let _canUpdate = boardUpdating == index;
      return (<div className="boardContained" id={id} key={index} ><JigsawSwapBoard fetchAuth={fetchAuth} game={game} index={index} canUpdate={_canUpdate} updateCallback={updateCallback} updatePinkCallback={setUpdatePink}/></div>)
    })

    return (
      <>
        <div className="topRow">
          <div className='pink'>
            <div className="pinkShard"/>
            <div className="alignPinkText big-white-text">x{pinkShards}</div>
            <div className="pinkPiece"/>
            <div className="alignPinkText big-white-text">x{pinkPieces}</div>
          </div>
          <div className="customizeButton_back" style={{top: 12, left: 400}} onClick={() => {navigator('/customize')}}>
            <div className="customizeButton_front"/>
          </div>
        </div>
        <div className='boardRow'>
            {multiBoard}
        </div>
        <div className="tinyText">
          Type !jigsaw in chat to showoff
        </div>
      </>
    )
}