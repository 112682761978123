import React, { useState } from 'react'
import "./JigsawTile.css"

export default function JigsawTile ({gap, piece_width, piece_height, piece_columns, index, active, isnew, isduplicate, fetchAuth, canUpdate, background, game, usedPinkCallback}) {
    const [clickPop, setClickPop] = useState(0);
    const [pinkUsed, setPinkused] = useState(false);

    const bgLeft = gap + ((index % piece_columns) * (piece_width));
    const bgTop = gap + (Math.floor(index / piece_columns) * (piece_height));
    const top = bgTop + (Math.floor(index / piece_columns))
    const left = bgLeft + ((index % piece_columns));
    
    const width = piece_width - gap;
    const height = piece_height - gap;

    const blue = "#e0e2ff";
    const pink = "#ff88af";

    var tileStyle = {
        width,
        height,
        top,
        left,
        backgroundPosition: `-${bgLeft}px -${bgTop}px`
        //backgroundImage: 'url('+background+')'
    }
    
    if (!canUpdate && !pinkUsed){
      isnew = false;
      isduplicate = false;
    }

    if (pinkUsed){
      active = true;
    }

    if (active){
      tileStyle.backgroundImage = 'url("'+background+'")';
    }

    var tileGlowStyle = {
      width,
      height,
      top,
      left,
      backgroundPosition: `-${bgLeft}px -${bgTop}px`,
      backgroundColor: blue,
      color: blue,
      opacity: 0
    }

    if (isnew || isduplicate || pinkUsed){
      tileGlowStyle.opacity = 1;
    } else {
      tileGlowStyle.animation = "none";
    }

    if (isduplicate || pinkUsed){
      tileGlowStyle.color = pink;
      tileGlowStyle.backgroundColor = pink;
    }

    let clickFunction = () => {
      //setClickPop((prevValue) => {return prevValue+1})
      //console.log("HI")
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem("jwt_token") },
      body: ""+index
    };

    if (!active && !pinkUsed){
      clickFunction = () => {
        fetchAuth("/api/user/jigsaw/usepink/"+game,requestOptions)
            .then((parsedData) => {
                if (parsedData === "GOOD"){
                    usedPinkCallback((prevValue) => {return prevValue+1})
                    setPinkused(true);
                    //document.body.style.cursor='wait';
                }
            });
      }
      tileGlowStyle.cursor = 'pointer';
    }

    return <>
        <div 
        className='tile'
        style={tileStyle}
        />
        
        <div
        className='tileglow'
        style={tileGlowStyle}
        onClick={clickFunction}
        />

    </>;
}
