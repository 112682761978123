import React from 'react'
import TwitchAuthentication from './pages/authentication/TwitchAuthentication';
import PageCustomize from './pages/PageCustomize'
import {Route, Routes} from 'react-router-dom';
import AuthenticationFailed from './pages/authentication/AuthenticationFailed'
import JigsawSwap from './pages/JigsawSwap';
import PrivateRoute from './pages/authentication/PrivateRoute';
import CantFindPage from './pages/authentication/CantFindPage';

function App() {
  //<PageCustomize/>

  return (
    <div>
      <Routes>
      <Route exact path="/" element={<TwitchAuthentication/>} />
      <Route path="/aw" element={<AuthenticationFailed/>} />
      
      <Route exact path='/customize' element={<PrivateRoute/>}>
          <Route path="/customize" element={<PageCustomize/>} />
      </Route>

      <Route exact path='/jigsaw_swap' element={<PrivateRoute/>}>
          <Route path="/jigsaw_swap" element={<JigsawSwap/>} />
      </Route>
      <Route exact path='/jigsawswap' element={<PrivateRoute/>}>
          <Route path="/jigsawswap" element={<JigsawSwap/>} />
      </Route>

      <Route exact path='/jigsaw-swap' element={<PrivateRoute/>}>
          <Route path="/jigsaw-swap" element={<JigsawSwap/>} />
      </Route>

      <Route path='/jigsaw-swap/:game' element={<PrivateRoute/>}>
          <Route path="/jigsaw-swap/:game" element={<JigsawSwap/>} />
      </Route>

      <Route path="*" element={<CantFindPage />} />
      </Routes>
    </div>
  );
}

export default App;